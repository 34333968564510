import React from 'react'
import styled from 'styled-components'
import { useTranslation, Trans } from 'react-i18next'
import { useQuery } from 'react-query'

import { ProgressMeter, Tooltip } from '@junglescout/edna'
import { CircleInfoIcon } from 'icons/CircleInfoIcon/CircleInfoIcon'
import { membershipUsagePercentual } from 'helpers/market_insights/segments'

import { COLORS } from 'COLORS'

const Wrapper = styled.div`
  display: flex;
  padding-top: 10px;
  min-width: 220px;
  margin-right: 16px;
`

const ProgressBarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 11px;
  margin-bottom: auto;
  margin-top: 6px;
`
const PercentUsed = styled.div`
  min-width: 49px;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  margin-right: 6px;
  margin-top: 4px;
  color: ${COLORS.grey700};
`

const TokensAvailable = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: ${COLORS.grey700};
  margin-left: 0;
  margin-right: auto;
  margin-top: 4px;
  span {
    font-weight: 600 !important;
  }
`

const IconWrapper = styled.div`
  margin-left: 5px;
`

export const TokenUsageBar = () => {
  const { t } = useTranslation(['common'])
  const { data } = useQuery(`tolkien/balance`, {
    refetchOnMount: true,
    refetchInterval: 60000
  })

  const availableTokens = data?.remainingTokens || 0
  const usedTokens = data?.tokensSpent || 0
  const totalToken = availableTokens + usedTokens
  const usedTokenPercent = membershipUsagePercentual(totalToken, usedTokens)

  const tooltipContent = (
    <Trans i18nKey="common:TokenUsageBar.tooltip">
      Tokens are used when a new segment is processed or when a segment
      refreshes. Your available tokens refresh each month.
    </Trans>
  )

  let barColor = COLORS.blue500
  if (usedTokenPercent >= 99) {
    barColor = COLORS.red500
  } else if (usedTokenPercent >= 90) {
    barColor = '#FF8400'
  } else if (usedTokenPercent >= 80) {
    barColor = COLORS.yellow500
  }

  return (
    <Wrapper>
      {usedTokenPercent >= 80 && (
        <PercentUsed>
          {t(
            'common:TokenUsageBar.PercentUsed',
            ' {{ usedTokenPercent }}% used',
            { usedTokenPercent }
          )}
        </PercentUsed>
      )}
      <Tooltip content={tooltipContent} side="bottom" size="large">
        <IconWrapper>
          <CircleInfoIcon color={COLORS.grey600} />
        </IconWrapper>
      </Tooltip>
      <ProgressBarWrapper>
        <ProgressMeter
          role="meter"
          value={Math.min(usedTokenPercent, 100)}
          max={100}
          color={barColor}
        />
        <TokensAvailable>
          <span>{availableTokens.toLocaleString()}</span>
          {t('common:TokenUsageBar.tokensAvailable', ' Tokens Available')}
        </TokensAvailable>
      </ProgressBarWrapper>
    </Wrapper>
  )
}
