import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { TEXT_STYLES } from 'TEXT_STYLES'
import { COLORS } from 'COLORS'

import { Button, ButtonType } from '@junglescout/edna'

import { mountWistia } from 'services/wistia'

import { Modal } from '../Modal/Modal'

const SubTitle = styled.h3`
  ${TEXT_STYLES.H3Black}
  margin: 0;
  padding: 0;
`

const Description = styled.p`
  ${TEXT_STYLES.BodyBlack}
  margin: 0;
  padding: 0;
`

const VideoFrame = styled.div`
  width: 900px;
  opacity: 0.99;
  border-radius: 2px;
  background-color: ${COLORS.grey100};
  padding: 15px;
  border: 1px solid ${COLORS.grey100};
  margin-top: 20px;
  margin-bottom: 20px;
`

export const Footer = styled.div`
  float: right;
  margin-top: 0px;
  margin-bottom: 15px;
  width: 100%;

  & > button:last-child {
    display: inline;
    float: right !important;
    padding: 7px !important;
    margin-right: 0;
  }
`

class TutorialModal extends Component {
  componentDidMount() {
    mountWistia()
  }

  renderVideo() {
    const { videoId } = this.props

    return (
      <div
        className="wistia_responsive_padding"
        style={{
          padding: '62.5% 0 0 0',
          position: 'relative',
          width: '100%',
          height: '100%'
        }}>
        <div
          className="wistia_responsive_wrapper"
          style={{
            height: '100%',
            left: '0',
            position: 'absolute',
            top: '0',
            width: '100%'
          }}>
          <div
            className={`wistia_embed wistia_async_${videoId} videoFoam=true autoPlay=true`}
            style={{ height: '100%', width: '100%' }}
          />
        </div>
      </div>
    )
  }

  renderFooter() {
    const { showFooter, onBackButtonClick } = this.props

    if (!showFooter) {
      return null
    }

    return (
      <Footer>
        <Button btnType={ButtonType.TERTIARY} onClick={onBackButtonClick}>
          Back
        </Button>
      </Footer>
    )
  }

  render() {
    const { title, subtitle, description } = this.props
    return (
      <Modal {...this.props} title={title}>
        <SubTitle>{subtitle}</SubTitle>
        <Description>{description}</Description>
        <VideoFrame>{this.renderVideo()}</VideoFrame>
        {this.renderFooter()}
      </Modal>
    )
  }
}

TutorialModal.defaultProps = {
  description: '',
  subtitle: '',
  title: 'Tutorial',
  videoId: '',
  showFooter: false,
  onBackButtonClick: () => {}
}
TutorialModal.propTypes = {
  description: PropTypes.string,
  subtitle: PropTypes.string,
  title: PropTypes.string,
  videoId: PropTypes.string,
  showFooter: PropTypes.bool,
  onBackButtonClick: PropTypes.func
}

export { TutorialModal }
