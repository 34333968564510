import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import styled, { withTheme } from 'styled-components'
import debounce from 'lodash/debounce'

const getBoxShadow = ({
  theme: { colors },
  shouldScroll,
  isScrolled,
  isScrolledToBottom,
  shadowColor
}) => {
  if (!shouldScroll) return

  const topShadow = `inset 0px 12px 12px -12px ${shadowColor ||
    colors.blackOpacity40}`
  const bottomShadow = `inset 0px -12px 12px -12px ${shadowColor ||
    colors.blackOpacity40}`

  if (isScrolled && isScrolledToBottom) {
    return `
      box-shadow: ${topShadow};
    `
  }

  if (isScrolled) {
    return `
      box-shadow: ${bottomShadow}, ${topShadow};
    `
  }

  return `
    box-shadow: ${bottomShadow};
  `
}

const VerticalScrollingBlockWrapper = styled.div`
  height: 100%;
  max-height: ${props => props.maxHeight};
  overflow-y: auto;
  ${props => getBoxShadow(props)}
`

const VerticalScrollingBlock = ({
  className,
  maxHeight,
  shadowColor,
  children
}) => {
  const blockRef = useRef()
  const [shouldScroll, setShouldScroll] = useState(false)
  const [isScrolled, setIsScrolled] = useState(false)
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false)

  const updateScrollState = () => {
    if (!blockRef.current) {
      return
    }

    const { scrollTop, offsetHeight, scrollHeight } = blockRef.current
    setIsScrolled(scrollTop > 0)
    setIsScrolledToBottom(Math.round(offsetHeight + scrollTop) >= scrollHeight)
    setShouldScroll(offsetHeight < scrollHeight)
  }

  useEffect(updateScrollState, [])

  useEffect(() => {
    const debouncedUpdateScrollState = debounce(() => updateScrollState(), 10)
    const componentRef = blockRef.current
    window.addEventListener('resize', debouncedUpdateScrollState)
    let observer
    if (window.MutationObserver) {
      const config = { attributes: true, subtree: true }
      observer = new MutationObserver(debouncedUpdateScrollState)
      // Start observing the target node for configured mutations
      observer.observe(componentRef, config)
    }
    return () => {
      if (observer) {
        observer.disconnect()
      }
      window.removeEventListener('resize', debouncedUpdateScrollState)
    }
  }, []) // Empty array ensures that effect is only run on mount and unmount

  return (
    <VerticalScrollingBlockWrapper
      ref={blockRef}
      className={className}
      maxHeight={maxHeight}
      shouldScroll={shouldScroll}
      isScrolled={isScrolled}
      isScrolledToBottom={isScrolledToBottom}
      onScroll={updateScrollState}
      shadowColor={shadowColor}>
      {children}
    </VerticalScrollingBlockWrapper>
  )
}

VerticalScrollingBlock.defaultProps = {
  maxHeight: '360px',
  shadowColor: null
}

VerticalScrollingBlock.propTypes = {
  maxHeight: PropTypes.string,
  shadowColor: PropTypes.string
}

const wrappedComponent = withTheme(VerticalScrollingBlock)

export { wrappedComponent as VerticalScrollingBlock }
