import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { ClickableIcon, Icon } from '@junglescout/edna'

import { setBannerShowAfterTime } from 'helpers/banners'

const calcBackgroundColor = ({ type, theme: { colors } }) => {
  if (type === 'error') {
    return colors.red100
  }

  if (type === 'warning') {
    return colors.yellow100
  }

  return colors.yellow100
}

const getAlertIconColor = type => {
  if (type === 'error') {
    return 'red500'
  }

  return 'yellow500'
}

const BannerWrapper = styled.div`
  padding-left: 18px;
  padding-right: ${props => (props.dismissible ? '20px' : '18px')};
  display: flex;
  justify-content: ${props => (props.dismissible ? 'space-between' : 'center')};
  align-items: center;
  width: 100%;
  background-color: ${props => calcBackgroundColor(props)};
  min-height: 48px;
`

const InnerWrapper = styled.div`
  display: flex;
  align-items: center;
`

const ActionRequired = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  margin-right: 5px;
`

const AlertIconWrapper = styled.div`
  display: inline-flex;
  padding-right: 5px;
`

const TopBanner = ({
  id,
  type,
  message,
  dismissible,
  onDismiss,
  showAfter,
  isCustom
}) => {
  const { t } = useTranslation(['generic'])
  const dispatch = useDispatch()

  const onClick = () => {
    onDismiss({ id })

    dispatch({
      type: 'REMOVE_BANNER',
      payload: id
    })

    setBannerShowAfterTime({ id, showAfter })
  }

  if (isCustom) {
    return <>{message}</>
  }

  return (
    <BannerWrapper type={type} dismissible={dismissible}>
      <InnerWrapper>
        <ActionRequired>
          <AlertIconWrapper>
            <Icon
              name="TOOLTIP_WARNING"
              height="37px"
              width="42px"
              color={getAlertIconColor(type)}
            />
          </AlertIconWrapper>
          <div>{t('generic:ActionRequired', 'Action Required')}:</div>
        </ActionRequired>
        <div>{message}</div>
      </InnerWrapper>

      {dismissible && (
        <ClickableIcon
          name="X_CLOSE"
          width="20px"
          height="20px"
          onClick={onClick}
          ariaLabel={`Close banner ${id}`}
        />
      )}
    </BannerWrapper>
  )
}

TopBanner.defaultProps = {
  message: undefined,
  dismissible: false,
  onDismiss: () => {},
  showAfter: -1,
  isCustom: false
}

TopBanner.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  dismissible: PropTypes.bool,
  onDismiss: PropTypes.func,
  showAfter: PropTypes.number,
  isCustom: PropTypes.bool
}

export { TopBanner }
