import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { DecreaseValueArrow } from 'icons/DecreaseValueArrow/DecreaseValueArrow'
import { IncreaseValueArrow } from 'icons/IncreaseValueArrow/IncreaseValueArrow'

const TrendWrapper = styled.div`
  display: flex;
  align-items: center;
  height: ${props => `${props.size}px`};
  font-size: ${props => `${props.size}px`};
  color: ${props =>
    props.isNegative ? props.theme.colors.red500 : props.theme.colors.green700};
  [class*='IconWrapper'] {
    margin-right: ${props => `${props.size / 4}px`};
  }
  ${props => props.alignRight && `justify-content: flex-end;`}
`

const Dashes = styled.div`
  display: flex;
  align-items: center;
  height: ${props => `${props.size}px`};
`

const Dash = styled.div`
  height: 2px;
  width: ${props => `${props.size / 2}px`};
  margin-right: 2px;
  border-radius: 3px;
  background-color: ${props => props.theme.colors.grey300};
`

export const TrendChangeText = props => {
  const {
    value,
    size,
    alignRight,
    className,
    zeroVsNoDataShareAndTrends
  } = props
  const valueF = typeof value === 'string' ? parseFloat(value) : value
  const roundedValue = Math.round(valueF)
  const iconHeight = `${size - 2}px`

  const formatTrendContent = value => {
    if (value < 0) {
      return (
        <>
          <DecreaseValueArrow height={iconHeight} />
          <div>{Math.abs(value).toLocaleString()}%</div>
        </>
      )
    }
    if (value > 0) {
      return (
        <>
          <IncreaseValueArrow height={iconHeight} />
          <div>{value.toLocaleString()}%</div>
        </>
      )
    }

    if (zeroVsNoDataShareAndTrends) return null

    return (
      <Dashes size={size}>
        <Dash size={size} />
        <Dash size={size} />
      </Dashes>
    )
  }

  return (
    <TrendWrapper
      isNegative={valueF < 0}
      size={size}
      alignRight={alignRight}
      className={className}>
      {formatTrendContent(roundedValue)}
    </TrendWrapper>
  )
}

TrendChangeText.defaultProps = {
  className: '',
  size: 12,
  alignRight: false,
  zeroVsNoDataShareAndTrends: false
}

TrendChangeText.propTypes = {
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  size: PropTypes.number,
  alignRight: PropTypes.bool,
  zeroVsNoDataShareAndTrends: PropTypes.bool
}
