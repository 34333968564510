import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Tooltip } from '@junglescout/edna'

import { DATABASE_TOOLTIPS } from 'constants/product_database/tooltips'
import { EMPTY_MARKER } from 'constants/table'
import { snakeCaseToCapitalizedWords } from 'helpers/strings'

const VariantDifferencesContainer = styled.div`
  display: flex;
  flex-direction: column;
`
const DifferenceContainer = styled.div`
  display: flex;
  justify-content: flex-start;
`

const ELLIPSES = '...'

const renderSupplement = items => {
  const formattedList = (
    <>
      {items.map(item => {
        return <div key={item}>{snakeCaseToCapitalizedWords(item)}</div>
      })}
    </>
  )

  return (
    <Tooltip content={formattedList} side="bottom" key="tooltip">
      <span>{ELLIPSES}</span>
    </Tooltip>
  )
}

const VariantDifferences = ({ productLevel, fullList }) => {
  if (!(fullList?.length > 0) && productLevel === 'parent') {
    return EMPTY_MARKER
  }
  if (!(fullList?.length > 0) && productLevel === 'variant') {
    return (
      <Tooltip
        content={DATABASE_TOOLTIPS.nullProductData.noVariantDifferences}
        side="bottom"
        size="medium">
        <span>{EMPTY_MARKER}</span>
      </Tooltip>
    )
  }

  let truncated = fullList.slice(0, 2)
  const excess = fullList.slice(2, fullList.length)

  if (fullList.length > 2) {
    truncated = [...truncated, ELLIPSES]
  }

  return (
    <VariantDifferencesContainer>
      {truncated.map(item => {
        if (item === ELLIPSES) return renderSupplement(excess)
        return (
          <DifferenceContainer key={item}>
            {snakeCaseToCapitalizedWords(item)}
          </DifferenceContainer>
        )
      })}
    </VariantDifferencesContainer>
  )
}

VariantDifferences.defaultProps = {
  fullList: undefined
}
VariantDifferences.propTypes = {
  productLevel: PropTypes.string.isRequired,
  fullList: PropTypes.arrayOf(PropTypes.any)
}

export { VariantDifferences }
